import { Injectable } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaUtilsService } from './meta-utils.service';
import { environment } from '@env';
import { getContentTypeByFile } from '../constants/app.constants';

@Injectable({ providedIn: 'root' })
export class MetaService {
    private DEFAULTS = {
        TITLE: 'WishTrip - Travel Album Creation App',
        DESCRIPTION:
            'Turn your experiences into memories. Upload your treks, photos and movies and share it all with friends, family, and the world on WishTrip',
        IMAGE_URL: `${environment.ASSETS_BASE_URL}/assets/images/social-share-img.jpg`
    };
    private imageMetaSelectors = [
        'og:image',
        'og:image:url',
        'og:image:secure_url'
    ];
    private imageMetaTypeSelector = 'og:image:type';
    private imageSecondaryTags: MetaDefinition[] = [
        {
            property: 'og:image:width',
            content: '1200'
        },
        {
            property: 'og:image:height',
            content: '630'
        }
    ];
    private videoMetaSelectors = [
        'og:video',
        'og:video:url',
        'og:video:secure_url'
    ];
    private videoMetaTypeSelector = 'og:video:type';
    private videoSecondaryTags: MetaDefinition[] = [
        {
            property: 'og:video:width',
            content: '1280'
        },
        {
            property: 'og:video:height',
            content: '720'
        }
    ];

    private PRERENDER_TAG = 'prerender-status-code';

    constructor(private metaUtilsService: MetaUtilsService) {}

    resetTags() {
        // Set tags with default values.
        this.setTags();
    }

    setTags(
        pageName: string = this.DEFAULTS.TITLE,
        description: string = this.DEFAULTS.DESCRIPTION,
        imageUrl: string = this.DEFAULTS.IMAGE_URL,
        videoUrl?: string,
        pageCanonicalURL?: string
    ) {
        this.setUrlTag(pageCanonicalURL || location.href);
        this.setTitleTags(pageName);
        this.setDescriptionTags(description);
        this.setImageTags(imageUrl);
        this.setVideoTags(videoUrl!);
    }

    setPrerenderDontCrawlTag() {
        this.metaUtilsService.addTag({
            name: this.PRERENDER_TAG,
            content: '404'
        });
    }

    removePrerenderDontCrawlTag() {
        const selector = `name="${this.PRERENDER_TAG}"`;
        if (this.metaUtilsService.getTag(selector)) {
            this.metaUtilsService.removeTag(selector);
        }
    }

    private setUrlTag(url: string) {
        this.metaUtilsService.updateTag({
            property: 'og:url',
            content: url
        });
        (document.getElementById('canonical') as HTMLLinkElement).href = url;
    }

    /**
     *
     *
     * Set title element and OpenGraph title tag.
     *
     *
     *
     * @param title
     */
    private setTitleTags(title: string) {
        this.metaUtilsService.updateTag({
            property: 'og:title',
            content: title
        });
        if (!document.getElementsByTagName('title')[0]) {
            const head = document.getElementsByTagName('head')[0];
            const createTitle = document.createElement('title');
            createTitle.innerHTML = title;
            head.appendChild(createTitle);
        } else {
            document.getElementsByTagName('title')[0].innerHTML = title;
        }
    }

    /**
     *
     *
     * Set description metaPreview and OpenGraph
     *
     *
     *
     * @param description
     */
    private setDescriptionTags(description: string) {
        this.metaUtilsService.updateTag({
            property: 'og:description',
            content: description
        });
        this.metaUtilsService.updateTag({
            name: 'description',
            content: description
        });
    }

    private setImageTags(imageUrl: string) {
        this.removeImageTags();

        if (imageUrl) {
            this.addImageTags(imageUrl);
        }
    }

    private setVideoTags(videoUrl: string) {
        this.removeVideoTags();

        if (videoUrl) {
            this.addVideoTags(videoUrl);
        }
    }

    /**
     * Remove all OpenGraph video tags
     */
    private removeVideoTags() {
        this.videoMetaSelectors.forEach((selector: string) =>
            this.metaUtilsService.removeTag(`property="${selector}"`)
        );
        this.videoSecondaryTags.forEach((tag: MetaDefinition) =>
            this.metaUtilsService.removeTag(`property="${tag.property}"`)
        );
        this.metaUtilsService.removeTag(
            `property="${this.videoMetaTypeSelector}"`
        );
    }

    private addVideoTags(videoUrl: string) {
        this.metaUtilsService.updateTag({
            property: this.videoMetaTypeSelector,
            content: getContentTypeByFile(videoUrl)
        });
        this.videoMetaSelectors.forEach((selector: string) =>
            this.metaUtilsService.updateTag({
                property: selector,
                content: videoUrl
            })
        );
        this.metaUtilsService.addTags(this.videoSecondaryTags);
    }

    /**
     * Remove all OpenGraph image tags
     */
    private removeImageTags() {
        this.imageMetaSelectors.forEach((selector: string) =>
            this.metaUtilsService.removeTag(`property="${selector}"`)
        );
        this.imageSecondaryTags.forEach((tag: MetaDefinition) =>
            this.metaUtilsService.removeTag(`property="${tag.property}"`)
        );
        this.metaUtilsService.removeTag(
            `property="${this.imageMetaTypeSelector}"`
        );
    }

    private addImageTags(imageUrl: string) {
        this.metaUtilsService.updateTag({
            property: this.imageMetaTypeSelector,
            content: getContentTypeByFile(imageUrl)
        });
        this.imageMetaSelectors.forEach((selector: string) =>
            this.metaUtilsService.updateTag({
                property: selector,
                content: imageUrl
            })
        );
        this.metaUtilsService.addTags(this.imageSecondaryTags);
    }
    setRobotTag(rule: string = 'index, follow') {
        this.metaUtilsService.addTag({
            name: 'robots',
            content: rule
        });
    }
}
